import request from "@/utils/request"

// 用户列表
export function listPage(query) {
  return request({
    url: '/apiUserGinseng/listPage',
    method: "get",
    params:query
  })
}
//批量导出用户数据
export function exportExcel(data) {
  return request({
    url: '/apiUserGinseng/exportApiUser',
    method: 'post',
    responseType: "blob",
    data
  })
}

